<template>
  <el-config-provider :locale="store.getters.language === 'en' ? en : zhCn">
    <router-view v-if="!isLoading" />
    <rechange-dialog v-if="!isLoading"></rechange-dialog>
    <exchange-dialog v-if="!isLoading"></exchange-dialog>
    <!-- <upgrade-notice-dialog v-if="!isLoading"></upgrade-notice-dialog> -->
  </el-config-provider>
</template>

<script setup>
import rechangeDialog from '@/components/common/rechangeDialog'
import exchangeDialog from '@/components/common/exchangeDialog'
// import upgradeNoticeDialog from '@/components/common/upgradeNoticeDialog'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getQuery, getCookie } from 'utils'
import { initTheme, initLang } from 'app/style'
import emitter from 'app/emitter'
import { wx_auth } from '@/services/auth'

const store = useStore()
const router = useRouter()
const route = useRoute()
const isLoading = ref(true)
const init = async () => {
  // initQihooLogin()
  initQuc()
  initTheme()
  initLang()
  if (location.href.indexOf('wndoreport') === -1) {
    initUserInfo()
  } else {
    isLoading.value = false
  }
}

const initQuc = () => {
  var head = document.getElementsByTagName('head')[0]
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.onload = script.onreadystatechange = function() {
    // callback()
    initQihooLogin()
    // Handle memory leak in IE
    script.onload = script.onreadystatechange = null
  }
  script.src = 'https://s.ssl.qhimg.com/quc/quc7.js?t=' + parseInt((new Date()).getTime() / 1000)
  head.appendChild(script)
}
// const axios = require('axios')

// const [appid, appsecret] = ['wxd5e1b4659d14e069', '08391f3a306b15748b81bda78bd59286'] // 公众号平台获取此信息
// // 获取 access_token
// const accessTokenUrl = `https://api.weixin.qq.com/cgi-bin/token?grant_type=${'client_credential'}&appid=${appid}&secret=${appsecret}`
// const r = await axios.get(accessTokenUrl)
// const access_token = r.data

// // 获取 jsapi_ticket
// const ticketUrl = `https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=${access_token}&type=jsapi`
// const res = await axios.get(ticketUrl)
// const { errcode, ticket, expires_in } = res.data
// console.log('jsapi_ticket', ticket)
function isWeixinBrowser() {
  // 使用正则表达式检测navigator.userAgent中是否包含'MicroMessenger'
  return /micromessenger/i.test(navigator.userAgent)
}
if (location.href.indexOf('wndoreport') === -1 && isWeixinBrowser()) {
  wx_auth({ url: location.href }).then(res => {
    const { app_id, timestamp, noncestr, signature } = res
    // eslint-disable-next-line no-undef
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: app_id, // 必填，公众号的唯一标识
      timestamp: String(timestamp), // 必填，生成签名的时间戳
      nonceStr: noncestr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
    })
    // eslint-disable-next-line no-undef
    // wx.config({
    //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //   appId: 'wxfe0645de74ea3bf2', // 必填，公众号的唯一标识
    //   timestamp: '1675223385', // 必填，生成签名的时间戳
    //   nonceStr: '2Df1MjbN6kWx3tVO', // 必填，生成签名的随机串
    //   signature: '70cbb529585cf664e8aa0d8a8b37b44cb91b8a27', // 必填，签名
    //   jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
    // })
    // eslint-disable-next-line no-undef
    wx.ready(() => {
      // eslint-disable-next-line no-undef
      wx.updateAppMessageShareData({
        title: '360 沙箱云', // 分享标题
        desc: '360沙箱云 - 专业的高级威胁分析平台，洞悉恶意样本每一行为', // 分享描述
        link: window.location.href,
        imgUrl: 'https://ata.360.net/wx_logo.png',
        success: function() {
        // 设置成功
          console.log(112)
        }
      })
    })
  })
}

const initUserInfo = async () => {
  const token = store.getters.token
  const sid = getQuery('sid')
  const code = getQuery('code')
  const sign = getQuery('sign')
  const q = getCookie('Q')
  isLoading.value = true
  setTimeout(async () => {
    if (route.name === 'license') {
      isLoading.value = false
      return
    }
    if (token) {
      await store.dispatch('user/getUserInfo')
    } else if (q) {
      await store.dispatch('user/login')
    } else if (sid) {
      const ref = window.location.href
      await store.dispatch('user/login', { sid, ref })
    } else if (code) {
      await store.dispatch('user/login', { code })
    } else if (sign) {
      const ref_from = getQuery('from')
      await store.dispatch('user/login', { sign, ref_from })
    }
    isLoading.value = false
  }, 300)
}

const initQihooLogin = () => {
  window.QHPass.init({
    src: 'pcw_sandboxcloud',
    domainList: ['360.cn', '360.net', '360.com'],
    supportHttps: ['360.com', '360.cn', '360.net'],
    signIn: {
      types: ['normal', 'mobile', 'qrcode'],
      panelCloseHandler() {
        emitter.emit('closeLoginPanel')
        if (route.meta && route.meta.auth) {
          router.replace({
            name: 'Home'
          })
        }
      },
      thirdPart: {
        providers: ['weixin', 'tencent']
      }
    },
    signUp: {
      panelCloseHandler() {
        emitter.emit('closeLoginPanel')
        if (route.meta && route.meta.auth) {
          router.replace({
            name: 'Home'
          })
        }
      }
    }
  })
  window.QHPass.events.on('afterShow.signUp', function(e) {
    const dom = window.$('.quc-form-item.quc-field.quc-form-item-checkbox > label')
    const html = dom.html()
    const src =
      '/help/docs/terms'
    const src1 =
      '/help/docs/privacy'
    dom.html(
      `${html}<a href="${src}" target="_blank" class="quc-link">《360沙箱云用户协议》</a>和<a href="${src1}" target="_blank" class="quc-link">《360沙箱云隐私政策》`
    )
  })
}

onMounted(() => {
  init()
  emitter.on('reloadScore', (data) => {
    if (data.onloadPage) {
      router.go(0)
    }
  })
})
</script>

<style lang="scss">
.frequencyMsgBox {
  .frequencyLink {
    margin: 0 4px;
  }

  .frequencyLink:link, .frequencyLink:visited {
    color: #00ab7a;
    text-decoration: none;
  }
}
.detectMessage {
  top: 75px !important;
}
</style>
