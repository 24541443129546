export default {
  price: {
    free: 'Free plan',
    speciality: 'Pro plan',
    renew: 'Renewal',
    upgrad: 'upgrade',
    professional: 'Pro Plus plan',
    enterprise: 'Enterprise plan',
    contact_us: 'contact us',
    day_month: 'daily/monthly',
    basic_detection: 'Basic detection  features',
    windows32: 'Windows 7 32 bit',
    windows32des: 'As one of the most popular and stable operating systems in the world, Windows 7 32 bit operating system has been widely used by people for nearly 10 years. It has the characteristics of fast processing speed and low application noise. It is the system of choice for detecting known or new threats.',
    basic_software: 'Basic software environment set',
    basic_software_des: 'Provides application environment combinations for Internet Explorer 8/9/11, Adobe Flash Player ActiveX 17/21/29, Adobe Acrobat Reader 9/DC 18, and Microsoft Office 2007/2010/2013, supporting the analysis of detection object behavior in different application software environments .',
    file_size: 'Max input file size',
    file_size_des: 'Uploaded detection file size limit.',
    detection_time: 'Timeout',
    detection_time_des: 'Supports configuring the duration of analysis tasks. In reality, not all malware enters the active stage immediately after being launched. Some samples require additional actions or time consumption before launching the attack.',
    detection_file: 'Detection file parameter settings',
    detection_file_des: 'Supports the setting of common file parameters during the detection process, such as file type, file execution path, command line, file name, file extension, etc. The correct setting of such parameters has a positive effect on the accuracy of the detection results.',
    file_way: 'Email specific processing method',
    file_way_des: 'Supports setting the processing method of email files, including extracting attachments and executing them, and opening them with applications.',
    operator_imitate: 'Human operation simulation',
    operator_imitate_des: 'Supports human operation simulation behaviors performed by auxiliary modules when setting up dynamic detection, including automatic clicking, automatic scrolling, clipboard filling, and mouse movement.',
    fast_detection: 'Fast dynamic detection',
    fast_detection_des: 'Supports setting whether the detection process ends early when all target processes end during dynamic detection.',
    runtime_restart: 'Simulate restart while running',
    runtime_restart_des: 'Supports setting whether to use the simulated restart function during dynamic detection. When it senses that the target process adds boot auto-start items or scheduled tasks, the detection module actively executes the target path or behavior.',
    violent_password: 'Brute force password cracking',
    violent_password_des: 'Supports setting whether to brute force the password of compressed files during dynamic detection.',
    hook_sleep: 'Sleep Skip',
    hook_sleep_des: 'Support setting whether to skip sample sleep during dynamic detection',
    analysis_priority: 'Analysis Priority',
    low: 'Low',
    middle: 'middle',
    high: 'high',
    analysis_priority_des: 'Tasks with high priority will be assigned task analysis resources faster, reducing the time waiting for analysis results and improving analysis efficiency.',
    att_ck: 'ATT&CK matrix mapping',
    att_ck_des: 'A structured understanding of threats is very important in threat analysis. Most behavioral rules are mapped to the ATT&CK matrix in a more understandable way.',
    relation_tree: 'Behavior diagram and process tree',
    relation_tree_des: 'Provides a process tree and behavior diagram of the sample analysis process. The process tree can intuitively see the relationship between processes, and the behavior relationship diagram can reveal a lot of additional information, such as the type of running files and released files.',
    common_tag: 'Normal label',
    common_tag_des: 'Provide common labels for detection, such as network connections and scheduled tasks, and associate sample behaviors with common labels to intuitively see possible dangerous behaviors during sample execution.',
    family_tag: 'Malware family tags',
    family_tag_des: 'Provides labels for detected malware families and associates sample behavior with malware families to visually identify possible dangerous behaviors during the execution of the sample.',
    api_call: 'REST API',
    api_call_des: 'Provides functions such as submitting tests and obtaining reports through the API interface to facilitate sample detection and analysis in device linkage/automation scenarios.',
    linux_detection: 'Linux dynamic detection',
    linux_detection_des: 'Supports Linux dynamic detection and analyzes sample behavior during sample execution.',
    ubuntu64: 'Linux Ubuntu 18.04 64 bit',
    ubuntu64_des: 'Provides Ubuntu 18.04 64 bit operating system analysis environment.',
    advance_detection: 'Advanced detection features',
    window64: 'Windows 7 64 bit',
    window64_des: 'Certain types of malware can only run on 64 bit operating systems, and extending the operating system to 64 bit can help reveal the full vector of attacks specifically targeting this architecture, as well as obtain additional payloads.',
    andorid_detection: 'Android dynamic detection',
    andorid_detection_des: 'Supports Android system dynamic detection and analyzes sample behavior during sample execution.',
    task_setting: 'Privacy',
    task_setting_des: 'Task and report contents are only visible to the user, and users can also set the report to be public.',
    delete_report: 'Delete task report',
    delete_report_des: `Supports deleting user's own task reports.`,
    advance_set: 'Advanced software environment set',
    advance_set_des: 'Expand the application environment combination of Adobe Flash Player ActiveX 31, Microsoft Office 2016, HWP 2010/2016, WPS 2012/2016, Firefox 50/55, Chrome 58/65, and increase the richness of application environment simulation.',
    url_browser: 'URL task specifies browser',
    url_browser_des: 'The latest exploits always cause different problems for different browsers. Therefore, checking suspicious URLs in just one browser will not reveal virtually all attack vectors. We offer a variety of browsers for paying users.',
    file_open: 'The document file is opened by the specified application',
    file_open_des: 'Supports setting the application that opens the document file. Including Microsoft Office, WPS.',
    compressed_file: 'Specify the password to decompress the compressed file',
    compressed_file_des: 'Supports setting the decompression password for compressed files.',
    compressed_subfile: 'Specify subfiles in compressed files',
    compressed_subfile_des: 'Supports specifying subfiles in the compressed file to be executed. Including specifying MD5, specifying file name, and specifying file type.',
    file_password: 'Specify the password to open the document file',
    file_password_des: 'Supports setting the viewing password of document files.',
    opetation_install: 'Human operation simulates automatic installation',
    opetation_install_des: 'Supports automatic installation simulation behavior performed by auxiliary modules when setting up dynamic detection.',
    https_decrype: 'HTTPS Decryption',
    https_decrype_des: 'More and more malicious applications are using secure connections to hide their attack traffic. By using the HTTPS decryption feature, you can see the decrypted traffic and perform detection and analysis.',
    file_information: 'File depth static information',
    file_information_des: 'Provides static information of file detection objects, in-depth extraction of strings, Office embedded objects, and email bodies.',
    vul_tag: 'Common exploit tags',
    vul_tag_des: 'Provide special application software (such as flash, VBScript) and common vulnerability exploitation behavior detection tags to facilitate the discovery of vulnerability exploitation behavior.',
    ransomware_tag: 'Ransomware Behavior Tags',
    ransomware_tag_des: 'Provides behavioral labels for ransomware hits during the detection process.',
    evasion_tag: 'Detect evasive behavior tags',
    evasion_tag_des: 'Provide detection evasion behavior labels hit during the detection process.',
    sample_pcap: 'Export samples and PCAP',
    sample_pcap_des: 'Support export samples and PCAP',
    download_pdf: 'Export PDF report',
    download_pdf_des: 'Supports export PDF reports.',
    release_file: 'Export dropped file',
    release_file_des: 'Support Export dropped file.',
    release_memdump: 'Export dropped Memory',
    release_memdump_des: 'Support Export dropped Memory',
    view_release: 'View released memory',
    view_release_des: 'Supports viewing released memory.',
    view_threat: 'View threat',
    view_threat_des: 'Supports viewing threat.',
    deep_detection: 'Depth detection features',
    windows8: 'Windows 8 32/64 bit',
    windows8_des: 'Provides Windows 8 32 bit and 64 bit operating system analysis environments.',
    windows10: 'Windows 10 LTSB 2016 32/64 bit',
    windows10_des: 'Provides operating system analysis environments for Windows 10 32 bit and 64 bit.',
    deep_sofrware: 'Deep set of software environments',
    deep_sofrware_des: 'Provides Windows 8.1 32 bit and 64 bit operating system analysis environments.',
    date_time_set: 'Date and time settings',
    date_time_set_des: 'Support users to set time and date to meet the trigger conditions of specific samples.',
    zone_set: 'Time zone settings',
    zone_set_des: 'Support users to set the time zone to meet the trigger conditions of specific samples.',
    regional_set: 'Locale selection',
    regional_set_des: 'Supports Locale selection to meet trigger conditions for specific samples.',
    environment_set: 'Environment variable settings',
    environment_set_des: 'Supports users to customize system environment variables to meet trigger conditions for specific samples.',
    library_set: 'Dynamic library parameter settings',
    library_set_des: 'Supports user-defined dynamic library calling parameters to meet trigger conditions for specific samples.',
    active_restart: 'Active restart',
    active_restart_des: 'Supports the restart operation of the sample detection process, and the data before and after restart can be correlated for analysis.',
    fake_av: 'Simulate antivirus software process',
    fake_av_des: 'Support settings to simulate opening or closing anti-virus software processes and windows',
    known_vul_tag: 'Known vulnerability tags',
    known_vul_tag_des: 'Provides known vulnerability detection labels, discovers various popular known vulnerabilities, and gives the hit CVE number.',
    key_vul_tag: 'Critical exploit tags',
    key_vul_tag_des: 'Key common vulnerability exploit labels are provided so that the exploit process cannot be bypassed, helping to detect 0-day vulnerabilities.',
    apt_tag: 'APT organization tag',
    apt_tag_des: 'Provide labels of detected threat organizations, promptly discover threat organizations that launch attacks, and take timely countermeasures.',
    view_analysis: 'View the correlation analysis function',
    view_analysis_des: 'Supports viewing the IOC information obtained during the analysis process for correlation analysis.',
    ai_assistant: 'Artificial Intelligence Analysis Assistant',
    ai_assistant_des: 'Supports asking questions about the sandbox to the AI analysis assistant.',
    qihoo: 'Exclusive customized service',
    advanced_search: 'Advanced Search',
    advanced_search_des: 'Accurately locate threat information through multi-dimensional field search',
    auota_restriction: 'Limits',
    delivery_frequency: 'Submission Limits (requests/ min)',
    delivery_frequency_des: 'Number of tasks allowed to be submitted per minute',
    customized: 'customize',
    times: '{num} times',
    search_frequency: 'Search limits (requests/ min)',
    search_frequency_des: 'The number of times data is allowed to be searched per min.',
    ai_frequency: 'Analysis assistant question limits (requests/ min)',
    ai_frequency_des: 'The number of questions allowed per min.',
    single_task: 'Single submission task limit',
    single_task_des: 'The number of detection objects allowed in one submission task.',
    numbers: '{num}',
    persons: '{num}',
    seconds: '{num} sec',
    task_number: 'Submission Limits (day)',
    task_number_des: 'The maximum number of tasks allowed to be submitted per day.',
    search_number: 'Search limits (requests/ day)',
    search_number_des: 'The maximum number of search results allowed per day. Multiple searches for the same keyword are recorded once. If the search result is empty, it will not be recorded.',
    view_task: 'View task limits (day)',
    view_task_des: 'The maximum number of task reports allowed to be displayed per day. The same task is viewed multiple times and the record is viewed once. If the task report does not exist, it will not be recorded.',
    advanced_search_number: 'Advanced search（day）',
    advanced_search_number_desc: 'The maximum number of searches allowed per day.',
    download_report: 'Export samples (day)',
    download_report_des: 'Maximum number of samples allowed to be exported per day.',
    download_pdf_num: 'Export pdf (day)',
    download_pdf_num_des: 'Maximum number of PDF allowed to be exported per day.',
    download_pcap_num: 'Export PCAP (day)',
    download_pcap_num_des: 'Maximum number of PCAP allowed to be exported per day.',
    download_release_num: 'Export dropped files (day)',
    download_release_num_des: 'Maximum number of dropped files allowed to be exported per day.',
    download_disk_num: 'Export Memdumps(day)',
    download_disk_num_des: 'Maximum number of Memdumps allowed to be exported per day.',
    ai_question_num: 'Analysis assistant questions (day)',
    ai_question_num_des: 'The maximum number of questions allowed per day.',
    teamwork: 'teamwork',
    create_team: 'Create a team',
    create_team_des: 'The number of teams allowed to be created.',
    add_team_num: 'Add team members',
    add_team_num_des: 'The number of team members a team is allowed to add.',
    team_nums: 'Total number of teams',
    team_nums_des: 'Allows users to be in several teams.'
  }
}
