<template>
  <div class="app-menu-standard">
    <div
      v-for="item in list"
      :key="item.path"
      class="app-menu-standard__item"
      :class="{ active: $route.path.indexOf(item.path) !== -1 || $route.path.indexOf(item.related) !== -1}"
      @click="onClick(item.path)"
    >
      {{ $t(`app.menu.${item.name}`) }} <span class="new" v-if="item.name === 'datasub'">NEW</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const isProduct = window.location.host === 'ata.360.net'
const menuList = ref([
  {
    path: '/detect',
    name: 'submit'
  },
  {
    path: '/taskhub',
    related: 'report',
    name: 'task'
  },
  // {
  //   path: '/hunting',
  //   name: 'hunting'
  // },
  {
    path: '/datasub',
    name: 'datasub'
  },
  {
    path: '/help',
    name: 'help'
  },
  {
    path: '/price',
    name: 'upgrade'
  }
])

const list = computed(() => {
  return !isProduct ? menuList.value : menuList.value.filter(item => item.path !== '/datasub')
})

const onClick = (path) => {
  router.push(path)
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.app-menu-standard {
  display: inline-flex;
  &__item {
    position: relative;
    display: inline-block;
    width: 104px;
    height: $header-height;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-2);
    cursor: pointer;
    &:hover {
      color: var(--color-text-1);
    }
    &.active {
      color: var(--color-text-1);
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: var(--color-priamry);
      }
    }
    .new {
      background: linear-gradient(90deg, #FF4646 4%, #FF7C62 100%);
      border-radius: 4px;
      font-size: 8px;
      font-weight: 500;
      color: #FFFFFF;
      padding: 2px 4px;
      margin-left: 4px;
    }
  }
}
</style>
