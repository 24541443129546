export default {
  namespaced: true,
  state: () => ({
    moduleCount: {}
  }),
  mutations: {
    setModuleCount(state, obj) {
      state.moduleCount = obj
    }
  },
  actions: {}
}
