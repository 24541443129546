<template>
  <div class="exchange">
    <el-dialog
      :width="`${64 + (155 + 20) * dialogData.length - 20}px`"
      v-model="visible"
      @close="close"
      :append-to-body="false"
    >
      <div class="exchange-title">
        <img src="@/assets/img/crown.svg" />
        {{ exchangeDialogType === 'C' ? $t('other.components.report.redemptionDownload') : $t('message.integral') }}
      </div>
      <div class="exchange-msg">
        <span class="exchange-msg__span1">{{ $t('other.components.report.existingPoints') }}</span>
        <span class="exchange-msg__span2"
          >{{ scoreInfo && scoreInfo.current_score }} <img src="@/assets/img/integral.svg"
        /></span>
        <span class="exchange-msg__span3" v-if="showLack">
          <img src="@/assets/img/warning.svg" />{{ $t('other.components.report.noPoints') }}</span
        >
        <span class="exchange-msg__span4" @click="open('/price')">{{ $t('other.components.report.aboutPaid') }}</span>
      </div>
      <div class="exchange-list">
        <div
          class="exchange-list__card"
          :class="`
            exchange-list__card${item.type}
            ${item.disabled ? 'exchange-list__card--disabled' : ''}
            ${cardActive === index ? 'exchange-list__cardActive' : ''}`"
          v-for="(item, index) in dialogData"
          :key="index"
          @click="changeCard(item, index)"
        >
          <img
            class="exchange-list__card--active"
            v-if="cardActive === index"
            :src="$img('exchangeCardActive.svg')"
          />
          <div class="exchange-list__card--version">{{ item.version }}</div>
          <div class="exchange-list__card--content">{{ item.content }}</div>
          <div class="exchange-list__card--duration">
            {{ item.duration
            }}<span class="exchange-list__card--after">{{ item.durationAfter }}</span>
          </div>
          <div class="exchange-list__card--money">
            <div class="exchange-list__card--line"></div>
            <span class="exchange-list__card--wait" v-if="item.wait">{{ item.wait }}</span>
            <span v-else> {{ item.need }}<img src="@/assets/img/integral.svg" /> </span>
          </div>
        </div>
      </div>
      <div
        class="exchange-msg__span5"
      >
        <span  v-if="dialogData[cardActive] && dialogData[cardActive].showMsg"><img src="@/assets/img/warning.svg" />{{ $t('other.components.report.exchangeText') }}</span>
      </div>
      <div class="exchange-btn">
        <div
          class="exchange-btn2"
          :class="{ 'exchange-btn--disabled': cardActive === undefined || showLack }"
          @click="purchase"
        >
        {{ $t('other.components.report.confirmRecharge') }}
        </div>
        <div class="exchange-btn1" @click="showRechange">
          <img src="@/assets/img/upgrade3.svg" />{{ $t('other.components.report.rechargePoints') }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { postPurchaseByScore } from 'services/score'
import { t } from 'app/i18n'
export default {
  data() {
    return {
      visible: false,
      dialogData: [],
      cardActive: undefined,
      // 防止重复提交手抖
      isPurchase: false,
      // 是否展示积分不足
      showLack: false,
      // 当前选择的卡片的信息
      activeItem: {},
      purchaseTypeMap: {
        A: 'count',
        B: 'time',
        C1: 'count',
        C2: 'count',
        C3: 'count',
        C4: 'count',
        D: 'time'
      }
    }
  },
  computed: {
    ...mapState(['exchangeDialogType', 'exchangeDialogVisible']),
    ...mapState({
      scoreInfo: (state) => state.user.scoreInfo,
      userinfo: (state) => state.user.userInfo
    })
  },
  watch: {
    // 弹窗打开的时候判断一下当前的积分用哪一套
    exchangeDialogVisible(data) {
      this.visible = data
      if (data) {
        if (this.exchangeDialogType === 'A') {
          this.dialogData = [
            {
              type: 1,
              version: t('UserGroup.professional'),
              content: t('point_manager.point_desc.task_conf_benefits'),
              duration: 5,
              durationAfter: t('account_manager.subscription.bout'),
              need: this.userinfo.group === 'professional' ? '-' : 50,
              disabled: this.userinfo.group !== 'free' || this.scoreInfo?.detection_pro_last !== 0
            },
            {
              type: 2,
              version: t('UserGroup.enterprise'),
              content: t('point_manager.point_desc.task_conf_benefits'),
              duration: 5,
              durationAfter: t('account_manager.subscription.bout'),
              // need: this.userinfo.group === 'professional' ? 30 : 80,
              need: 80,
              // 上一个版本存在余额选中的时候就要展示提示
              showMsg: this.scoreInfo?.detection_pro_last !== 0
            },
            {
              type: 3,
              version: t('UserGroup.professional'),
              content: t('point_manager.point_desc.task_conf_benefits'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              wait: t('other.components.report.stayTuned'),
              disabled: true
            },
            {
              type: 4,
              version: t('UserGroup.enterprise'),
              content: t('point_manager.point_desc.task_conf_benefits'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              wait: t('other.components.report.stayTuned'),
              disabled: true
            }
          ]
        } else if (this.exchangeDialogType === 'B') {
          this.dialogData = [
            {
              type: 1,
              version: t('UserGroup.professional'),
              content: t('other.components.report.displayRights'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              need: this.userinfo.group === 'professional' ? '-' : 30,
              // 只有免费版才显示，如果已经有未用完的权限也不显示
              disabled:
                this.userinfo.group !== 'free' ||
                (this.scoreInfo?.report_pro_expire &&
                  new Date(this.scoreInfo?.report_pro_expire.$date).getTime() >
                    new Date().getTime())
            },
            {
              type: 2,
              version: t('UserGroup.enterprise'),
              content: t('other.components.report.displayRights'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              // need: this.userinfo.group === 'professional' ? 20 : 50,
              need: 50,
              disabled: (this.userinfo.group !== 'free' && this.userinfo.group !== 'professional') ||
                (this.scoreInfo?.report_enter_expire &&
                  new Date(this.scoreInfo?.report_enter_expire.$date).getTime() >
                    new Date().getTime()),
              // 上一个版本存在余额选中的时候就要展示提示
              showMsg:
                this.scoreInfo?.report_pro_expire &&
                new Date(this.scoreInfo?.report_pro_expire.$date).getTime() > new Date().getTime()
            },
            {
              type: 3,
              version: t('UserGroup.professional'),
              content: t('other.components.report.allBenefits'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              wait: t('other.components.report.stayTuned'),
              disabled: true
            },
            {
              type: 4,
              version: t('UserGroup.enterprise'),
              content: t('other.components.report.allBenefits'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              wait: t('other.components.report.stayTuned'),
              disabled: true
            }
          ]
        } else if (this.exchangeDialogType === 'C1') {
          this.dialogData = [
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 10,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 200
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 20,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 400
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 50,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 1000
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 100,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 2000
            }
          ]
        } else if (this.exchangeDialogType === 'C2') {
          this.dialogData = [
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 10,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 200
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 20,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 400
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 50,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 1000
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 100,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 2000
            }
          ]
        } else if (this.exchangeDialogType === 'C3') {
          this.dialogData = [
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 10,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 200
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 20,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 400
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 50,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 1000
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 100,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 2000
            }
          ]
        } else if (this.exchangeDialogType === 'D') {
          this.dialogData = [
            {
              type: 1,
              version: t('UserGroup.professional'),
              content: t('other.components.report.displayTime'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              need: this.userinfo.group === 'professional' ? '-' : 30,
              // 只有免费版才显示，如果已经有未用完的权限也不显示
              disabled:
                this.userinfo.group !== 'free' ||
                (this.scoreInfo?.report_range_pro_expire &&
                  new Date(this.scoreInfo?.report_range_pro_expire.$date).getTime() >
                    new Date().getTime())
            },
            {
              type: 2,
              version: t('UserGroup.enterprise'),
              content: t('other.components.report.displayTime'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              // need: this.userinfo.group === 'professional' ? 20 : 50,
              need: 50,
              // 上一个版本存在余额选中的时候就要展示提示
              disabled: this.userinfo.group !== 'free' ||
                (this.scoreInfo?.report_range_enter_expire &&
                  new Date(this.scoreInfo?.report_range_enter_expire.$date).getTime() >
                    new Date().getTime()),
              showMsg:
                this.scoreInfo?.report_range_pro_expire &&
                new Date(this.scoreInfo?.report_range_pro_expire.$date).getTime() >
                  new Date().getTime()
            },
            {
              type: 3,
              version: t('UserGroup.professional'),
              content: t('other.components.report.allBenefits'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              wait: t('other.components.report.stayTuned'),
              disabled: true
            },
            {
              type: 4,
              version: t('UserGroup.enterprise'),
              content: t('other.components.report.allBenefits'),
              duration: 1,
              durationAfter: t('other.components.report.day'),
              wait: t('other.components.report.stayTuned'),
              disabled: true
            }
          ]
        } else if (this.exchangeDialogType === 'C4') {
          this.dialogData = [
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 10,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 200
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 20,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 400
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 50,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 1000
            },
            {
              type: 5,
              content: t('point_manager.point_desc.download_file'),
              duration: 100,
              durationAfter: t('account_manager.subscription.indivual'),
              need: 2000
            }
          ]
        }
      }
    }
  },
  methods: {
    ...mapMutations(['CLOSE_EXCHANGE_DIALOG', 'SHOW_RECHANGE_DIALOG']),
    // ...mapActions({
    //   getScoreInfo: 'user/getScoreInfo'
    // }),
    // 关闭的时候清空数据
    close() {
      this.visible = false
      this.cardActive = undefined
      this.showLack = false
      this.activeItem = {}
      this.CLOSE_EXCHANGE_DIALOG()
    },
    open(path) {
      window.open(path, '_blank')
    },
    // 去充积分，暂时不关闭兑换的弹窗
    showRechange() {
      // this.close()
      this.SHOW_RECHANGE_DIALOG()
    },
    // 卡片的切换
    changeCard(item, index) {
      if (item.disabled) return
      this.cardActive = index
      this.activeItem = item
      this.showLack = this.scoreInfo?.current_score < item.need
    },
    purchase() {
      this.$emitter.emit('reloadScore', {
        onloadPage: this.exchangeDialogType !== 'A'
      })
      if (this.isPurchase || this.cardActive === undefined || this.showLack) return
      this.isPurchase = true
      const params = {
        purchase_name: '',
        purchase_type: this.purchaseTypeMap[this.exchangeDialogType],
        purchase_score: this.activeItem.need,
        purchase_count: this.activeItem.duration
      }
      // 分配提交的参数
      if (this.exchangeDialogType === 'A') {
        if (this.cardActive === 0) {
          params.purchase_name = 'detection_pro'
        } else if (this.cardActive === 1) {
          params.purchase_name = 'detection_enter'
        }
      } else if (this.exchangeDialogType === 'B') {
        if (this.cardActive === 0) {
          params.purchase_name = 'report_pro'
        } else if (this.cardActive === 1) {
          params.purchase_name = 'report_enter'
        }
      } else if (this.exchangeDialogType === 'D') {
        if (this.cardActive === 0) {
          params.purchase_name = 'report_range_pro'
        } else if (this.cardActive === 1) {
          params.purchase_name = 'report_range_enter'
        }
      } else if (this.exchangeDialogType === 'C1') {
        params.purchase_name = 'download'
      } else if (this.exchangeDialogType === 'C2') {
        params.purchase_name = 'download'
      } else if (this.exchangeDialogType === 'C3') {
        params.purchase_name = 'download'
      } else if (this.exchangeDialogType === 'C4') {
        params.purchase_name = 'download'
      }
      postPurchaseByScore(params).then((res) => {
        this.isPurchase = false
        // 后端返回的错误
        if (res.code === 1) {
          // this.$store.commit('SHOWMESSAGE', { type: 'error', message: res.message, duration: 1000 })
          this.$store.commit('app/showMessage', {
            type: 'error',
            message: res.message,
            duration: 1000,
            showClose: true
          })
          return
        } else {
          // this.$store.commit('SHOWMESSAGE', {
          //   type: 'success',
          //   message: `兑换成功，${this.activeItem.version || ''}${this.activeItem.content} ${
          //     this.activeItem.duration
          //   }${this.activeItem.durationAfter}`,
          //   duration: 1000
          // })
          this.$store.commit('app/showMessage', {
            type: 'success',
            message: `${t('other.components.report.redemptionSuccessful')}，${this.activeItem.version || ''}${this.activeItem.content} ${
              this.activeItem.duration
            }${this.activeItem.durationAfter}`,
            duration: 1000,
            showClose: true
          })
          // 兑换成功重新加载积分
          this.$store.dispatch('user/getScoreInfo')
          this.close()
          // 兑换成功执行刷新页面
          // window.location.reload()
        }
        this.$emitter.emit('reloadScore', {
          onloadPage: this.exchangeDialogType !== 'A'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
[data-theme=light]{
  .exchange-list__cardActive {
    &.exchange-list__card5 {
      background-image: url('~@/assets/img/exchangeBg5Active-light.png');
    }
  }
  .exchange-list__card5 {
    background-image: url('~@/assets/img/exchangeBg5-light.png');
  }
}
.exchange {
  font-size: 14px;
  color: var(--color-text-1);

  & :deep(.el-dialog) {
    background: var(--detect-config-bg);
    box-shadow: 0 2px 8px 0 #00000026;
    border-radius: 2px;
    & .el-dialog__header {
      padding: 0;
      border: none;
    }

    & .el-dialog__body {
      padding: 32px;
    }

    & .el-dialog__headerbtn:focus .el-dialog__close, & .el-dialog__headerbtn:hover .el-dialog__close {
      color: var(--color-text-1);
    }
  }

  &-title {
    font-family: 'TencentSans W7';
    font-size: 16px;
    color: var(--score-title);
    line-height: 24px;
    margin-bottom: 20px;
  }

  &-msg {
    font-size: 14px;
    color: var(--color-text-1);
    margin-bottom: 16px;

    &__span1 {
      font-family: 'TencentSans W7';
      margin-right: 8px;
    }

    &__span2 {
      font-family: 'TencentSans W7';
      & img {
        transform: translateY(1px);
        margin-left: 6px;
      }
    }

    &__span3 {
      font-family: PingFangSC-Medium;
      color: #F56C6C;
      margin-left: 20px;

      & img {
        margin-right: 4px;
        transform: translateY(1px);
      }
    }

    &__span4 {
      color: #00ab7a;
      float: right;
      cursor: pointer;
    }

    &__span5 {
      font-family: PingFangSC-Medium;
      color: #F56C6C;
      margin-top: 16px;
      height: 17px;
      & img {
        margin-right: 4px;
        transform: translateY(1px);
      }
    }
  }

  &-list {
    font-size: 14px;
    color: #333333;
    display: flex;
    justify-content: space-between;

    &__card {
      height: 188px;
      width: 155px;
      text-align: center;
      position: relative;
      cursor: pointer;
      background-size: 100%;
      border-radius: 6px;
      &:hover {
        filter: brightness(1.05);
      }

      &--active {
        position: absolute;
        right: -8px;
        top: -8px;
      }

      &--disabled {
        filter: brightness(0.5);
        cursor: not-allowed;

        &:hover {
          filter: brightness(0.5);
        }
      }

      &--version {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        margin-top: 18px;
      }

      &--content {
        color: rgba(51, 51, 51, 0.8);
        margin-top: 13px;
      }

      &--duration {
        font-family: 'TencentSans W7';
        font-size: 32px;
        margin-top: 19px;
      }

      &--after {
        font-size: 12px;
        margin-left: 4px;
      }

      &--line {
        width: 146px;
        height: 1px;
        background-image: linear-gradient(90deg, rgba(217, 155, 116, 0) 0%, rgba(174, 124, 68, 0.6) 50%, rgba(217, 155, 116, 0) 100%);
      }

      &--money {
        font-family: 'TencentSans W7';
        height: 30px;
        line-height: 30px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);

        & img {
          margin-left: 4px;
          transform: translateY(1px);
        }
      }
    }

    &__card1 {
      background-image: url('~@/assets/img/exchangeBg1.png');
    }

    &__card2 {
      background-image: url('~@/assets/img/exchangeBg2.png');

      .exchange-list__card--line {
        background-image: linear-gradient(90deg, rgba(210, 208, 204, 0) 0%, rgba(167, 167, 166, 0.6) 50%, rgba(210, 208, 204, 0) 100%);
      }
    }

    &__card3 {
      background-image: url('~@/assets/img/exchangeBg3.png');

      .exchange-list__card--line {
        background-image: linear-gradient(90deg, rgba(217, 176, 116, 0) 0%, rgba(174, 131, 68, 0.6) 50%, rgba(217, 176, 116, 0) 100%);
      }
    }

    &__card4 {
      background-image: url('~@/assets/img/exchangeBg4.png');
      color: #F9DFB0;

      .exchange-list__card--content {
        color: #F9DFB0;
      }

      .exchange-list__card--line {
        background-image: linear-gradient(90deg, rgba(217, 176, 116, 0) 0%, rgba(174, 131, 68, 0.6) 50%, rgba(217, 176, 116, 0) 100%);
      }
    }

    &__card5 {
      background-image: url('~@/assets/img/exchangeBg5.png');
      color: var(--color-text-1);

      .exchange-list__card--content {
        color: var(--color-text-1);
        font-size: 16px;
      }

      .exchange-list__card--duration {
        margin-top: 38px;
      }

      .exchange-list__card--line {
        background-image: linear-gradient(90deg, rgba(217, 155, 116, 0) 0%, rgba(174, 131, 68, 0.6) 50%, rgba(217, 155, 116, 0) 100%);
      }
    }

    &__cardActive {
      filter: brightness(1.05);
      &.exchange-list__card5 {
        background-image: url('~@/assets/img/exchangeBg5Active.png');
      }
    }
  }

  &-btn1 {
    width: 108px;
    height: 32px;
    border-radius: 16px;
    color: var(--score-btn);
    text-align: center;
    line-height: 32px;
    border: 1px solid var(--score-btn);
    float: right;
    margin-right: 16px;
    cursor: pointer;

    & img {
      margin-right: 4px;
    }
  }

  &-btn2 {
    width: 108px;
    height: 32px;
    border-radius: 16px;
    background-image: linear-gradient(155deg, #EAC998 0%, #D5A763 100%);
    color: #331503;
    text-align: center;
    line-height: 32px;
    float: right;
    cursor: pointer;
  }

  &-btn {
    height: 32px;
    margin-top: 20px;

    &--disabled {
      background: #8B8B8B;
      color: #C7C7C7;
      cursor: not-allowed;
    }
  }
}
</style>
