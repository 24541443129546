import { ElMessage } from 'element-plus'
import { t } from 'app/i18n'

export function copy(msg) {
  const input = document.createElement('input')
  input.value = msg
  document.body.appendChild(input)
  input.select()
  document.execCommand('Copy')
  document.body.removeChild(input)
  ElMessage({
    message: t('copied'),
    type: 'success',
    showClose: true,
    customClass: 'custom-z-index-message'
  })
}
