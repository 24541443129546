export default {
  price: {
    free: '免费版',
    speciality: '专业版',
    renew: '续费',
    upgrad: '升级',
    professional: '专业增强版',
    enterprise: '企业版',
    contact_us: '联系我们',
    day_month: '每日/每月',
    basic_detection: '基础检测特性',
    windows32: 'Windows 7 32位',
    windows32des: 'Windows 7 32 位操作系统作为全球最流行、最稳定的操作系统之一，被人们广泛使用近10年，且具有处理速度快和应用噪点低的特点，是检测已知或新威胁的首选系统。',
    basic_software: '基础软件环境集',
    basic_software_des: '提供 Internet Explorer 8/9/11、Adobe Flash Player ActiveX 17/21/29、Adobe Acrobat Reader 9/DC 18、Microsoft Office 2007/2010/2013 的应用环境组合，支持分析不同应用软件环境中的检测对象行为。',
    file_size: '文件大小限制',
    file_size_des: '上传的检测文件大小限制。',
    detection_time: '检测时长上限',
    detection_time_des: '支持配置分析任务的时长。现实中并不是所有的恶意软件在启动后就立即进入活跃阶段，部分样本要求额外的动作或时间消耗后才会启动攻击行为。',
    detection_file: '检测文件参数设定',
    detection_file_des: '支持对检测过程中的常见文件参数进行设定，如文件类型、文件执行路径、命令行、文件名和文件扩展名等，该类参数的正确设定对检测结果的准确性有正向作用。',
    file_way: '邮件指定处理方式',
    file_way_des: '支持设置邮件文件的处理方式，包括提取附件并执行、用应用程序打开。',
    operator_imitate: '人类操作模拟',
    operator_imitate_des: '支持设置动态检测时辅助模块执行的人类操作模拟行为，包括自动点击、自动滚动、剪贴板填充、鼠标移动。',
    fast_detection: '快速动态检测',
    fast_detection_des: '支持设置动态检测时目标进程全部结束时的检测流程是否提前结束。',
    runtime_restart: '运行时模拟重启',
    runtime_restart_des: '支持设置动态检测时是否使用模拟重启功能，当感知到目标进程添加开机自启动项、计划任务时由检测模块主动执行目标路径或行为。',
    violent_password: '暴力破解密码',
    violent_password_des: '支持设置动态检测时是否暴力破解压缩文件的密码。',
    hook_sleep: '睡眠跳过',
    hook_sleep_des: '支持设置动态检测时是否跳过样本睡眠',
    analysis_priority: '分析优先级',
    low: '低',
    middle: '中',
    high: '高',
    analysis_priority_des: '优先级高的任务会被更快的安排任务分析资源，减少等待分析结果的时间，提高分析效率。',
    att_ck: 'ATT&CK 矩阵映射',
    att_ck_des: '威胁分析中结构化理解威胁非常重要，大部分行为规则，都以更易于理解的方式映射到ATT&CK矩阵。',
    relation_tree: '行为关系图与进程树',
    relation_tree_des: '提供样本分析过程的进程树和行为关系图。进程树可直观的看出进程间的关系，行为关系图可发现许多额外信息，如运行文件的类型和释放文件。',
    common_tag: '普通标签',
    common_tag_des: '提供检出的普通标签，比如网络连接、计划任务，将样本行为和普通标签进行关联，从而直观看出样本在执行过程中可能存在的危险行为。',
    family_tag: '恶意软件家族标签',
    family_tag_des: '提供检出的恶意软件家族标签，将样本行为和恶意软件家族进行关联，从而直观看出样本在执行过程中可能存在的危险行为。',
    api_call: 'API 接口调用',
    api_call_des: '提供通过 API 接口提交检测和获取报告等功能，便于设备联动/自动化场景的样本检测分析。',
    linux_detection: 'Linux动态检测',
    linux_detection_des: '支持Linux动态检测，在样本执行过程中分析样本行为。',
    ubuntu64: 'Ubuntu 18.04 64位',
    ubuntu64_des: '提供 Ubuntu 18.04 64位的操作系统分析环境。',
    advance_detection: '高级检测特性',
    window64: 'Windows 7 64位',
    window64_des: '某些类型的恶意软件只能在 64 位操作系统上运行，扩展操作系统到 64 位有助于揭示专门针对这种架构攻击的全部矢量，以及获得额外的有效载荷。',
    andorid_detection: 'Android动态检测',
    andorid_detection_des: '支持Android系统动态检测，在样本执行过程中分析样本行为。',
    task_setting: '任务权限设定',
    task_setting_des: '任务和报告内容仅为用户本人可见，也支持用户将报告设置为公开。',
    delete_report: '删除任务报告',
    delete_report_des: '支持删除用户自己的任务报告。',
    advance_set: '高级软件环境集',
    advance_set_des: '扩展 Adobe Flash Player ActiveX 31、Microsoft Office 2016、HWP 2010/2016、WPS 2012/2016、Firefox 50/55, Chrome 58/65 的应用环境组合，增加应用环境模拟的丰富性。',
    url_browser: 'URL任务指定浏览器',
    url_browser_des: '最新的利用工具总是会针对不同的浏览器产生不同的问题。因此，仅在一个浏览器中检查可疑的 URL 不能够展示实际上所有的攻击矢量。我们提供多种浏览器供付费用户使用。',
    file_open: '文档文件指定应用打开',
    file_open_des: '支持设置打开文档文件的应用程序。包括Microsoft Office、WPS。',
    compressed_file: '指定压缩文件解压密码',
    compressed_file_des: '支持设置压缩文件的解压密码。',
    compressed_subfile: '指定压缩文件中的子文件',
    compressed_subfile_des: '支持指定被执行的压缩文件中的子文件。包括指定MD5、指定文件名、指定文件类型。',
    file_password: '指定文档文件打开密码',
    file_password_des: '支持设置文档文件的查看密码。',
    opetation_install: '人类操作模拟自动安装',
    opetation_install_des: '支持设置动态检测时辅助模块执行的自动安装模拟行为。',
    https_decrype: 'HTTPS解密',
    https_decrype_des: '越来越多的恶意应用程序使用安全连接来隐藏它们的攻击流量。使用 HTTPS 解密特性可以看到解密后的流量并进行检测和分析。',
    file_information: '文件深度静态信息',
    file_information_des: '提供文件检测对象的静态信息，字符串、Office 嵌入对象、邮件正文的深度提取。',
    vul_tag: '普通漏洞利用标签',
    vul_tag_des: '提供专项应用软件（如 flash 、VBScript）和普通漏洞利用行为检出标签，便于发现漏洞利用行为。',
    ransomware_tag: '勒索病毒行为标签',
    ransomware_tag_des: '提供检测过程中命中的勒索病毒行为标签。',
    evasion_tag: '检测逃避行为标签',
    evasion_tag_des: '提供检测过程中命中的检测逃避行为标签。',
    sample_pcap: '下载样本和PCAP包',
    sample_pcap_des: '支持下载样本和 PCAP 包。',
    download_pdf: '下载PDF报告',
    download_pdf_des: '支持下载PDF报告。',
    release_file: '下载释放文件',
    release_file_des: '支持下载释放文件。',
    release_memdump: '下载释放内存',
    release_memdump_des: '支持下载释放内存。',
    view_release: '查看释放内存功能',
    view_release_des: '支持查看释放内存。',
    view_threat: '查看威胁指标功能',
    view_threat_des: '支持查看威胁指标。',
    deep_detection: '深度检测特性',
    windows8: 'Windows 8 32/64位',
    windows8_des: '提供 Windows 8 32 位和 64 位的操作系统分析环境。',
    windows10: 'Windows 10 LTSB 2016 32/64位',
    windows10_des: '提供 Windows 10 32 位和 64 位的操作系统分析环境。',
    deep_sofrware: '深度软件环境集',
    deep_sofrware_des: '提供 Windows 8.1 32 位和 64 位的操作系统分析环境。',
    date_time_set: '日期和时间设置',
    date_time_set_des: '支持用户对时间和日期设定，以满足特定样本的触发条件。',
    zone_set: '时区设置',
    zone_set_des: '支持用户对时区设定，以满足特定样本的触发条件。',
    regional_set: '所在区域设置',
    regional_set_des: '支持用户所在区域设定，以满足特定样本的触发条件。',
    environment_set: '环境变量设置',
    environment_set_des: '支持用户自定义系统的环境变量，以满足特定样本的触发条件。',
    library_set: '动态库参数设置',
    library_set_des: '支持用户自定义动态库调用参数，以满足特定样本的触发条件。',
    active_restart: '主动重启',
    active_restart_des: '支持样本检测过程的重启操作，重启前后的数据可以关联起来进行分析。',
    fake_av: '模拟杀软进程',
    fake_av_des: '支持设置模拟打开或关闭杀毒软件进程、窗口',
    known_vul_tag: '已知漏洞标签',
    known_vul_tag_des: '提供已知漏洞检出标签，发现各类热门的已知漏洞，并给出命中的 CVE 编号。',
    key_vul_tag: '关键漏洞利用标签',
    key_vul_tag_des: '提供关键通用漏洞利用标签，漏洞利用过程无法绕过，有助于检出 0 day 漏洞。',
    apt_tag: 'APT 组织标签',
    apt_tag_des: '提供检出的威胁组织标签，及时发现发起攻击的威胁组织，及时采取应对措施。',
    view_analysis: '查看关联分析功能',
    view_analysis_des: '支持查看分析过程中得到的ioc信息进行关联分析。',
    ai_assistant: '人工智能分析助手',
    ai_assistant_des: '支持向人工智能分析助手提问关于沙箱的问题。',
    qihoo: '尊享定制服务',
    advanced_search: '高级检索',
    advanced_search_des: '通过多维度字段搜索，准确定位威胁信息',
    auota_restriction: '配额/限制',
    delivery_frequency: '投递频率限制（每分钟）',
    delivery_frequency_des: '每分钟允许投递任务的次数。',
    customized: '定制',
    times: '{num}次',
    search_frequency: '搜索频率限制（每分钟）',
    search_frequency_des: '每分钟允许搜索数据的次数。',
    ai_frequency: '分析助手提问频率限制（每分钟）',
    ai_frequency_des: '每分钟允许发送提问的次数。',
    single_task: '单次提交任务数量限制',
    single_task_des: '允许一次提交任务中包含的检测对象数量。',
    numbers: '{num}个',
    persons: '{num}人',
    seconds: '{num}秒',
    task_number: '提交任务配额（每天）',
    task_number_des: '每天允许提交的任务上限。',
    search_number: '搜索次数配额（每天）',
    search_number_des: '每天允许展示搜索结果上限。同一关键词多次搜索记录1次。搜索结果为空则不记录。',
    view_task: '查看任务配额（每天）',
    view_task_des: '每天允许展示任务报告上限。同一任务多次查看记录1次。任务报告不存在则不记录。',
    advanced_search_number: '高级检索配额(每天)',
    advanced_search_number_desc: '每天允许搜索的次数上限。',
    download_report: '下载样本配额（每天）',
    download_report_des: '每天允许下载的样本数量上限。',
    download_pdf_num: '下载PDF报告配额（每天）',
    download_pdf_num_des: '每天允许下载的PDF报告数量上限。',
    download_pcap_num: '下载PCAP配额（每天）',
    download_pcap_num_des: '每天允许下载的PCAP数量上限。',
    download_release_num: '下载释放文件配额（每天）',
    download_release_num_des: '每天允许下载的释放文件数量上限。',
    download_disk_num: '下载释放内存配额（每天）',
    download_disk_num_des: '每天允许下载的释放内存数量上限。',
    ai_question_num: '分析助手提问配额（每天）',
    ai_question_num_des: '每天允许发送的提问次数上限。',
    teamwork: '团队合作',
    create_team: '创建团队',
    create_team_des: '允许创建团队的数量。',
    add_team_num: '添加团队成员',
    add_team_num_des: '允许一个团队添加的团队成员数量。',
    team_nums: '所在团队总数',
    team_nums_des: '允许用户可在几个团队。'
  }
}
