// directives/permission.js

export default {
  mounted(el, binding) {
    console.log('[ binding ] >', binding)
    // 检查是否有权限
    const hasPermission = binding.value

    if (!hasPermission) {
      // 创建蒙层元素
      const mask = document.createElement('div')
      mask.classList.add('no-permission-mask')
      mask.innerText = '暂无权限'

      // 设置蒙层样式
      Object.assign(mask.style, {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        zIndex: 10,
        pointerEvents: 'none' // 阻止蒙层对下层元素的点击影响
      })

      // 设置父元素的样式以确保定位有效
      const parent = el.style.position
      if (!parent || parent === 'static') {
        el.style.position = 'relative'
      }

      // 将蒙层添加到当前元素
      el.appendChild(mask)
      el._mask = mask // 存储在元素实例中，方便后续操作
    }
  },
  updated(el, binding) {
    const hasPermission = binding.value

    if (el._mask) {
      el._mask.style.display = hasPermission ? 'none' : 'flex'
    }
  },
  unmounted(el) {
    // 在元素卸载时移除蒙层
    if (el._mask) {
      el.removeChild(el._mask)
      delete el._mask
    }
  }
}
