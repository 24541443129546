import { login, logout, loginGithub, getTiToken, getAccountSign } from '@/services/auth'
import { getAccountInfo, getAccountSubscribe } from '@/services/account'
import { TOKEN, GPT_LIST } from '@/constants'
import { setItem, getItem, removeCookie } from 'utils'
import { getScoreInfo } from '@/services/score'
import { getMenus } from 'services/report'

import cloneDeep from 'lodash/cloneDeep'
import { t } from 'app/i18n'

let interval = null
export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    tiToken: null,
    userInfo: {},
    gptList: [
      {
        isOwner: false,
        message: t('chatgpt.chat.message.tip')
      }
    ],
    scoreInfo: null,
    taskhubMenus: [
      {
        zhcn_name: '公开任务',
        show: true,
        is_default: true,
        name: 'public'
      },
      {
        zhcn_name: '我的任务',
        show: true,
        is_default: false,
        name: 'history'
      },
      {
        zhcn_name: '团队任务',
        show: true,
        is_default: false,
        name: 'teamtask'
      },
      // {
      //   zhcn_name: '全站任务',
      //   show: true,
      //   is_default: false,
      //   name: 'global'
      // },
      {
        zhcn_name: '高级搜索',
        show: false,
        is_default: false,
        disabled: true,
        name: 'advanced'
      },
      {
        zhcn_name: '我的统计',
        show: true,
        is_default: false,
        name: 'statistics'
      }
    ]
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setTiToken(state, token) {
      state.tiToken = token
    },
    setGptList(state, list) {
      state.gptList = list
      // 持久化存储
      console.log(state.userInfo.user_id)
      setItem(GPT_LIST, {
        user: state.userInfo.user_id,
        list: list
      })
    },
    logout(state) {
      this.commit('user/setUserInfo', {})
      this.commit('user/setToken', '')
      this.commit('user/setTiToken', '')
      removeCookie('Q', { domain: '.360.cn' })
      removeCookie('Q', { domain: '.360.com' })
      removeCookie('Q', { domain: '.360.net' })
    },
    setMenus(state, list) {
      state.taskhubMenus = list
    }
  },
  actions: {
    async login(context, params = {}) {
      try {
        if (params.code) {
          await loginGithub(params)
        } else if (params.sign) {
          await getAccountSign(params)
        } else {
          await login(params)
        }
        await this.dispatch('user/getUserInfo')
      } catch {}
      if (params.code || params.sid) {
        const url = location.href
        let index
        if (params.code) {
          index = url.lastIndexOf('code')
        } else {
          index = url.lastIndexOf('sid')
        }
        const newUrl = url.substring(0, index - 1)
        history.pushState({}, '', newUrl)
      }
    },
    async getUserInfo(context) {
      const { user } = await getAccountInfo()
      if (user && user.username) {
        // 处理ti免登录过期问题
        interval && clearInterval(interval)
        interval = setInterval(() => {
          getTiToken().then(tiTokeData => {
            this.commit('user/setTiToken', tiTokeData.token)
          }).catch(() => {
            interval && clearInterval(interval)
          })
        }, 1000 * 60 * 10)
        const tiTokeData = await getTiToken()
        this.commit('user/setTiToken', tiTokeData.token)
        // userInfo.group = 'free'
        this.commit('user/setUserInfo', user)
        this.commit('user/setToken', user.api_key)
        if (getItem(GPT_LIST) && getItem(GPT_LIST).user === user.user_id) {
          if (getItem(GPT_LIST).list && getItem(GPT_LIST).list.length) {
            this.commit('user/setGptList', getItem(GPT_LIST).list)
          } else {
            this.commit('user/setGptList', [{
              message: t('chatgpt.chat.message.tip'),
              isOwner: false
            }])
          }
        } else {
          setItem(GPT_LIST, undefined)
        }
        await this.dispatch('user/getScoreInfo')
        await this.dispatch('user/getTaskhubMenus')
        await this.dispatch('user/getQuota')
      }
    },
    async logout(context) {
      try {
        await logout()
        this.commit('user/logout')
        interval && clearInterval(interval)
      } catch {}
    },
    async getScoreInfo({ state }) {
      getScoreInfo().then((res) => {
        state.scoreInfo = res
      })
    },
    async getTaskhubMenus({ state }) {
      getMenus().then((res) => {
        const arr = res.menus.filter(v => v.name !== 'advanced')
        state.taskhubMenus = arr
        window.taskhubMenus = cloneDeep(arr)
      })
    },
    async getQuota({ state }) {
      getAccountSubscribe().then((res) => {
        const userInfo = state.userInfo
        // res.quota['submit.max_file_size'].quota = 524288000
        userInfo.quota = res.quota
        this.commit('user/setUserInfo', userInfo)
      })
    }
  },
  getters: {
    menuDefault: state => {
      const name = state.taskhubMenus.find(v => v.is_default).name
      return name === 'advanced' ? 'advancedsearch' : name
    },
    menus: state => state.taskhubMenus,
    scoreInfo: state => state.scoreInfo,
    gid: state => state.userInfo.gid || 0
  }
}
