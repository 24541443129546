import { ElMessage } from 'element-plus'
import { CircleClose } from '@element-plus/icons-vue'
import { t } from 'app/i18n'

export default {
  namespaced: true,
  state: () => ({
    isIntranet: true,
    loginOpened: false,
    messagebox: null
  }),
  mutations: {
    setIntranet(state, isIntranet) {
      state.isIntranet = isIntranet
    },
    toggleLoginOpened(state) {
      state.loginOpened = !state.loginOpened
    },
    closeMessage(state) {
      if (state.messagebox) {
        state.messagebox.close()
      }
    },
    showMessage(
      state,
      {
        type = 'success',
        // message = '提示',
        message = t('other.tip'),
        duration = 1500,
        offset = 70,
        showClose = false,
        dangerouslyUseHTMLString = false,
        toPrice = false,
        toUpgrade = false,
        toGroup = 'enterprise'
      }
    ) {
      if (state.messagebox) {
        state.messagebox.close()
      }
      let msg = message.value ?? message
      if (toUpgrade) {
        msg +=
          ` <a style="color: #00ab7a;margin-left: 8px;" href="/usercenter/subscribe?type=purchase&meal_type=professional&price_type=one_year" target="_blank">${t('message.upgrade')}</a>`
        // msg += ` <a href="/usercenter/subscribe?type=upgrade&group=${toGroup}" target="_blank">升级</a>`
      }
      if (toPrice) {
        msg += ` <a style="color: #00ab7a;margin-right: 20px;" href="/price" target="_blank">${t('message.detail')}</a>`
      }
      const errObj = {
        icon: <CircleClose />,
        customClass: 'el-message--error detectMessage'
      }
      let obj = {
        type,
        message: msg,
        duration,
        offset,
        showClose,
        dangerouslyUseHTMLString,
        customClass: 'detectMessage'
      }
      if (type === 'error') obj = { ...obj, ...errObj }
      state.messagebox = ElMessage(obj)
    }
  },
  actions: {}
}
