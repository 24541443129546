export default {
  report: {
    title: 'Report: {0} - 360 ATA Cloud',
    overview: {
      platform: 'Platform',
      file_type: 'File type',
      task_type: 'Task type',
      specific_count: {
        threaten_quota: 'Indicators',
        host: 'Hosts',
        virus_name: 'Virus',
        dropped: 'Dropped',
        process: 'Processes',
        signatures_quota: 'Rules'
      },
      chart: {
        non_task: 'No tasks to view',
        dynamic_score: 'Dynamic',
        static_score: 'Static',
        is_threaten: {
          malice: 'Malicious',
          suspicious: 'Suspicious',
          secure: 'Clean'
        }
      },
      operate: {
        share: {
          name: 'Share',
          weibo: 'Share to Weibo',
          wechat: 'Share to Wechat',
          twitter: 'Share to Twitter',
          facebook: 'Share to Facebook',
          title: 'Focus on sample analysis report: {0} #360atacloud#'
        },
        handle: {
          name: 'Operate',
          range: {
            title: 'Visibility',
            public: 'Public',
            private: 'Private'
          },
          team: 'Team sharing',
          non_team: 'No teams',
          delete: 'Delete task'
        },
        redetect: 'Re-detect',
        download: {
          name: 'Export',
          sample: 'Export sample',
          pcap: 'Export PCAP',
          report: 'Export report',
          pdf: 'Export PDF',
          permissions_limited: 'Insufficient permissions',
          confirm: 'Confirm export sample',
          warm: 'You understand that content you download may contain viruses, malicious programs, code and may be destructive. You agree not to use any such malicious content for malicious purposes.',
          warm1: 'To ensure the security of your host, we compress and encrypt the sample and decompress the password:',
          undone: 'Unable to download from incomplete report',
          process: 'File downloading, please be patient!',
          ing: 'Report is being exported',
          done: 'Report export completed'
        }
      },
      detail: {
        environment: {
          label: 'Environment',
          static: 'Static analysis',
          suffix: '{0}-bit'
        },
        shutdown_on: 'Submission',
        continued: 'Duration',
        duration: 'Dynamic',
        formatter: '{0}s',
        config: {
          label: 'View',
          title: 'Task Config Details',
          task: {
            title: 'Task Config',
            second: 's',
            on: 'ON',
            off: 'OFF',
            auto_setting: 'Automatic',
            file_in_zip: 'Specify file name: ',
            md5_in_zip: 'Specify file MD5:',
            type_in_zip: 'Specify file type:',
            bit: '-bit',
            basic: {
              label: 'Basic Detection Config',
              children: [
                {
                  label: 'Fast detection',
                  default: 'Fast detection',
                  map: '@:(detection.config.basic.strategy)'
                },
                {
                  label: 'OS',
                  default: 'Windows 7 Professional Service Pack 1 32-bit (build: 7601)'
                },
                {
                  label: 'Language',
                  default: 'Simplified Chinese'
                }
              ]
            },
            dynamic: {
              label: 'Dynamic Detection Config',
              children: [
                {
                  label: 'Open URL in browser',
                  default: 'Internet Explorer'
                },
                {
                  label: 'File type',
                  default: 'Auto recognize'
                },
                {
                  label: 'Command line'
                },
                {
                  label: 'DLL parameters',
                  default: '-',
                  function: 'Function',
                  params: 'Parameter'
                },
                {
                  label: 'Decompress password'
                },
                {
                  label: 'Specify sub files in compressed files'
                },
                {
                  label: 'Document password'
                },
                {
                  label: 'Open document in Application'
                },
                {
                  label: 'Email file handle method'
                },
                {
                  label: 'Custom file name',
                  default: '-'
                },
                {
                  label: 'Custom extension',
                  default: '-'
                },
                {
                  label: 'Execute process under specified directory',
                  default: 'Auto setting'
                }
              ]
            },
            strategy: {
              label: 'Detection Policy Config',
              children: [
                {
                  label: 'Detection duration',
                  default: '120s'
                },
                {
                  label: 'Clicking simulation',
                  default: '-'
                },
                {
                  label: 'Human operation simulation'
                },
                {
                  label: 'Auto clicking'
                },
                {
                  label: 'Auto scrolling'
                },
                {
                  label: 'Clipboard padding'
                },
                {
                  label: 'Mouse movement'
                },
                {
                  label: 'Auto installation'
                },
                {
                  label: 'Fast dynamic detection'
                },
                {
                  label: 'Simulate reboot'
                },
                {
                  label: 'Active reboot'
                },
                {
                  label: 'Violent password cracking'
                },
                {
                  label: 'Time zone'
                },
                {
                  label: 'Location'
                },
                {
                  label: 'System date and time'
                },
                {
                  label: 'Environment variable'
                },
                {
                  label: 'Simulate AV process'
                },
                {
                  label: 'Sleep Skip'
                }
              ]
            },
            network: {
              label: 'Network Detection Config',
              children: [
                {
                  label: 'Internet connection',
                  default: 'OFF'
                },
                {
                  label: 'Decrypt HTTPS traffic',
                  default: 'OFF'
                }
              ]
            }
          },
          app: 'Applications',
          install: 'Updates',
          dependent: 'Components'
        }
      },
      process: 'Process list'
    },
    report_tabs: {
      analyse: 'Overview',
      task: 'Overview',
      static: 'Static',
      behavior: 'Behavior',
      network: 'Network',
      dropped: 'Files',
      memdump: 'Memory',
      indicator: 'Indicators',
      graph: 'Graph',
      full_report: 'View full report',
      process: 'process',
      event: 'event'
    },
    analyse: {
      aiengine: 'AI Engines',
      static: {
        title: 'Static Detection',
        engine_judgment: {
          title: 'Engine',
          risk: {
            malice: 'Malicious',
            suspicious: 'Suspicious',
            not_detected: 'Clean',
            non_risk: 'No risk'
          },
          key: 'Engine',
          result: 'Result',
          engine_key_map: {
            qvm_info: 'VECTOR MACHINE',
            aqvm_info: 'VECTOR MACHINE (M)',
            bd_info: 'ENHANCED SCANNER I',
            sign_info: 'SIGN WHITELIST',
            avira_info: 'ENHANCED SCANNER II',
            ave_info: 'HEURISTIC SCANNER',
            kpav_info: 'KUNPENG SCANNER',
            avm_info: 'HEURISTIC SCANNER (M)',
            qex_info: 'SCRIPT SCANNER',
            cloud_info: 'ENHANCED SCANNER III'
          }
        }
      },
      indicator: {
        marks: '{0} event(s)'
      },
      mitre_att: {
        title_label: 'MITRE ATT&CK™ Technique Detection',
        title_0: 'Exists',
        title_1: 'ATT&CK technique(s)',
        hide_missed: 'Hide missed techniques',
        dialog: {
          title: 'MITRE ATT&CK™ Technique',
          name: 'Name',
          tactics: 'Tactics',
          required_permissions: 'Required permissions',
          explain: 'Explain',
          source: 'Source',
          target: 'Indicators'
        }
      },
      behavior: {
        title: 'Behavior Detection',
        result: 'MITRE ATT&CK™ Technique Detection',
        show_all: 'Show all techniques',
        return_value: 'Return',
        repeat: 'Repeated times: {0}'
      },
      screenshoot: 'Running Screenshot',
      network: 'Network Overview',
      summary: {
        title: 'Behavior Summary',
        process_id: 'Process ID',
        process_name: 'Process name',
        search: 'Enter content to search for events'
      }
    },
    static: {
      info: {
        url_title: 'URL Info',
        file_title: 'File Info',
        file_label: {
          name: 'File name',
          size: 'File size',
          qex_type: 'File type',
          type: 'File format'
        }
      },
      content: {
        title: 'File Content',
        tab_data: 'Data',
        tab_string: 'Strings'
      },
      apk: {
        title: 'APK permissions',
        label: 'Permissions'
      },
      email: {
        title: 'Email Info',
        from: 'Sender',
        to: 'Receiver',
        cc: 'CC',
        subject: 'Subject',
        date: 'Date',
        attachment: 'Attachments',
        attachment_name: 'File name'
      },
      lnk: {
        title: 'LNK info',
        basepath: 'Base path',
        relapath: 'Relative path',
        cmdline: 'Command line',
        icon: 'Icon',
        description: 'Description'
      },
      email_body: 'Email Body',
      pe_info: {
        title: 'PE Info',
        pe_timestamp: 'Compile time',
        pe_imphash: 'Imphash',
        pdb_path: 'PDB path',
        pe_shells: 'PE shells'
      },
      pe_sections: {
        title: 'Sections',
        name: 'Name',
        virtual_address: 'Virtual Address',
        virtual_size: 'Virtual Size',
        size_of_data: 'Data Size',
        entropy: 'Entropy'
      },
      pe_resources: {
        title: 'Resources',
        name: 'Name',
        offset: 'Offset',
        size: 'Size',
        language: 'Language',
        sublanguage: 'Sub-language',
        filetype: 'File type'
      },
      pe_imports: 'Import Table',
      pe_exports: {
        title: 'Export Table',
        ordinal: 'Ordinal',
        address: 'Address',
        name: 'Name'
      },
      version: 'Version Info',
      signature: {
        title: 'Signatures',
        common_name: 'Common name',
        serial_number: 'Serial number',
        sha1: 'SHA1 fingerprint',
        md5: 'MD5 fingerprint'
      },
      zip_list: {
        title: 'Compressed File List',
        filename: 'File name',
        compress_size: 'Compressed size',
        file_size: 'Original size',
        date_time: 'Modified date'
      },
      office_summary: {
        title: 'OFFICE Info',
        _title: 'Title',
        subject: 'Subject',
        keywords: 'Keywords',
        category: 'Category',
        comments: 'Comments',
        author: 'Author',
        last_modified_by: 'Last modified by',
        revision: 'Revision',
        version: 'Version',
        created: 'Created time',
        modified: 'Modified time',
        content_status: 'Content status',
        language: 'Language'
      },
      vba_info: {
        title: 'VBA Info',
        filename: 'File name',
        stream: 'Stream'
      },
      eps_info: {
        title: 'EPS Info',
        eps_name: 'File name'
      },
      ole_info: {
        title: 'OLE Info',
        name: 'File name'
      },
      swf_info: {
        title: 'SWF Info',
        name: 'File name'
      },
      elf_info: {
        title: 'ELF Info',
        data: 'File name',
        ei_version: 'EI version',
        type: 'File type',
        machine: 'Machine',
        version: 'Version',
        number_of_program_headers: 'Program headers',
        number_of_section_headers: 'Section headers',
        entry_point_address: 'Entry point'
      },
      elf_sections: {
        title: 'ELF Sections',
        name: 'Name',
        addr: 'Address',
        size: 'Size',
        type: 'Type'
      },
      elf_relocations: {
        title: 'ELF Relocation Table',
        name: 'Name',
        offset: 'Offset',
        value: 'Value',
        info: 'Info',
        type: 'Type'
      },
      elf_program: {
        title: 'ELF Program Table',
        flags: 'Flags',
        addr: 'Address',
        size: 'Size',
        type: 'Type'
      },
      elf_symbol: {
        title: 'ELF Symbol Table',
        ndx_name: 'Index name',
        value: 'Value',
        bind: 'Bind',
        type: 'Type'
      }
    },
    behavior: {
      relation: {
        title: 'Behavioral Diagram',
        start: {
          label: 'Start',
          info: 'Behavior Info',
          sample: 'Sample',
          time: 'Start time',
          score: 'Score'
        },
        tooltip: {
          dropped: 'Dropped Files',
          domains: 'Domains',
          hosts: 'Hosts',
          http: 'HTTP',
          https: 'HTTPS',
          process_id: 'ID',
          process_name: 'Name',
          process_type: 'Type'
        },
        tools: {
          simple: 'Switch to simple mode',
          full: 'Switch to full mode',
          amplification: 'Zoom in',
          shrink: 'Zoom out',
          reset: 'Reset'
        },
        tree_icon: {
          start: 'START',
          zip: 'COMPRESSOR',
          system: 'SYSTEM TOOL',
          script: 'SCRIPT PROCESS',
          resources: 'FILE EXPLORER',
          registry: 'REGISTRY',
          pointBoard: 'MSPAINT',
          other: 'OTHER PROCESS',
          note: 'NOTEPAD',
          dynamic: 'DYNAMIC LIBRARY',
          droped: 'DROPPED FILES',
          drop: 'DROP',
          domains: 'DOMAINS',
          hosts: 'HOSTS',
          https: 'HTTPS',
          http: 'HTTP',
          calculator: 'CALCULATOR',
          command: 'COMMAND PROMPT',
          startProgress: 'START',
          dotted: 'FILE / NETWORK BEHAVIORS'
        }
      },
      tree: 'Process Tree',
      summary: {
        pid: 'Process ID',
        ppid: 'Parent ID',
        username: 'Username',
        integrity_sid: 'Integrity',
        first_seen: 'First seen',
        process_type: 'Process type',
        process_path: 'File path',
        user_sid: 'User SID',
        command_line: 'Command line',
        parent_path: 'Parent process path',
        parent_name: 'Parent process name',
        parent_cmdline: 'Parent process command line'
      },
      signature: 'Behavior Detection',
      events: {
        title: 'Behavior Events',
        detail: 'Detail',
        simple: 'Simple'
      }
    },
    status: {
      pending: 'Pending',
      scanning: 'Scanning',
      scanning_completed: 'Scanning completed',
      policy_running: 'Policy running',
      dynamic_pending: 'Dynamic pending',
      static_dispatch_waiting: 'Static dispatch waiting',
      dynamic_dispatch_waiting: 'Dynamic dispatch waiting',
      running: 'Running',
      processing: 'Processing',
      completed: 'Completing',
      reported: 'Reported',
      default: 'Failed'
    },
    network: {
      traffic: {
        alerts: {
          src_ip: 'Source IP',
          dst_ip: 'Destination IP',
          category: 'Type',
          severity: 'Severity',
          info: 'Traffic info',
          protocol: 'Protocol',
          src_port: 'Source port',
          detect: 'Traffic detection',
          signature: 'Signature',
          timestamp: 'Time stamp',
          payload: 'Payload'
        }
      },
      hosts: {
        ip: 'IP',
        country: 'Country / region',
        city: 'City',
        isp: 'ISP',
        info: 'Host info'
      },
      dns: {
        area_name: 'Domain',
        analyse: 'Result',
        info: 'Info',
        response: 'Response'
      },
      http: {
        info: 'Info',
        content: 'Data'
      },
      tcp: {
        src: 'Source IP',
        sport: 'Source port',
        dst: 'Destination IP',
        dport: 'Destination port',
        info: 'Info'
      },
      ftp: {
        command: 'Command'
      },
      smtp: {
        info: 'Info',
        dst: 'Destination IP',
        raw: 'Data'
      },
      icmp: {
        src: 'Source IP',
        type: 'ICMP type',
        dst: 'Destination IP',
        data: 'Data',
        info: 'Info'
      },
      irc: {
        command: 'Command',
        type: 'Type',
        params: 'Parameters',
        info: 'Info'
      }
    },
    release: {
      name: 'File name',
      filesize: 'File size',
      type: 'File type',
      engineJudge: 'Engine',
      filename: 'File name',
      filepath: 'File path',
      format: 'File format',
      info: 'File info',
      content: 'File data'
    },
    memory: {
      filesize: 'File size',
      filetype: 'File type',
      engineJudge: 'Engine',
      info: 'File info',
      content: 'Content',
      context: 'Context',
      type: 'File type',
      format: 'File format',
      context_pid: 'Context PID',
      target_pid: 'Target PID',
      address: 'Memory Address',
      size: 'Memory size',
      protection: 'Protection'
    },
    indicator: {
      ioc: 'Indicators',
      ioc_type: 'IOC type',
      threaten_type: 'Threaten',
      confidence_quota: 'Confidence',
      risk_quota: 'Risk',
      domain: 'Domain',
      ip: 'IP',
      file: 'File',
      threat: 'Threat',
      status: 'Status',
      not_expired: 'Effective',
      expired: 'Expired',
      organization: 'Organization',
      family: 'Family',
      tag: 'Tags'
    },
    graph: {
      file: 'File',
      domain: 'Domain',
      taskid: 'Task ID:',
      task_count: 'Task count:'
    },
    report_status: {
      private: 'This task is private and you do not have access permissions',
      error: 'System error',
      null: 'Task does not exist',
      denied: 'No permission to view the content of this task report',
      failed: 'Task detection failed'
    }
  },
  taskstatus: {
    tasklist: 'This task submission',
    history: 'View records in {mine}',
    mine: 'My Tasks',
    pending: 'Pending',
    scanning: 'Scanning',
    dynamic: 'Dispatching',
    running: 'Runnning',
    analyse: 'Processing',
    reported: 'Reported'
  }
}
