import { createApp, h, ref } from 'vue'
import { ElTooltip } from 'element-plus'
import { message } from '@/hooks/useUI'
import SvgIcon from '@/components/svg-icon'
import { isDark } from 'app/style'
import emitter from 'app/emitter'
import { t } from 'app/i18n'

export default {
  mounted(el, binding, vnode) {
    if (typeof binding.value === 'undefined') return
    const tooltipContainer = document.createElement('span')
    el.appendChild(tooltipContainer)

    const showIcon = ref(false)
    let type = ''
    let value = el.firstChild.textContent.trim()
    let isNum = false
    let show = true
    if (typeof binding.value === 'string') {
      type = binding.value
    } else {
      type = binding.value.type
      value = binding.value.value
      isNum = binding.value.isNum || false
      if (binding.value.show !== undefined) show = binding.value.show
    }
    if (!show) return
    const app = createApp({
      setup() {
        return { showIcon }
      },
      render() {
        return [
          h(ElTooltip, {
            content: t('copy'),
            placement: 'top',
            effect: isDark() ? 'dark' : 'light'
          }, {
            default: () => showIcon.value ? h(SvgIcon, {
              name: 'fuzhi1',
              class: 'copy-icon item-icon v-copy',
              onClick: () => {
                const tempInput = document.createElement('textarea')
                tempInput.style.position = 'absolute'
                tempInput.style.left = '-9999px'
                tempInput.value = value
                document.body.appendChild(tempInput)
                tempInput.select()
                document.execCommand('copy')
                document.body.removeChild(tempInput)
                message(t('chatgpt.chat.message.copySuccessful'))
              }
            }, '复制') : null
          }),
          h(ElTooltip, {
            content: '搜索',
            placement: 'top',
            effect: isDark() ? 'dark' : 'light'
          }, {
            default: () => showIcon.value ? h(SvgIcon, {
              name: 'sousuo',
              class: 'search-icon item-icon v-copy',
              onClick: () => {
                emitter.emit('ad-search', value, type, isNum)
              }
            }, '搜索') : null
          })
        ]
      }
    })

    el.addEventListener('mouseenter', () => {
      showIcon.value = true // 显示图标
    })
    el.addEventListener('mouseleave', () => {
      showIcon.value = false // 隐藏图标
    })

    app.mount(tooltipContainer)
    el._tooltipApp = app
  }
}
