<template>
  <svg :class="svgClass" :style="{fill:props.color}">
    <use class="copy" :xlink:href="iconName" />
  </svg>
</template>

<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
  // icon 图标
  name: {
    type: String,
    required: true
  },
  // 图标类名
  className: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'currentColor'
  }
})
/**
 * 项目内图标
 */
const iconName = computed(() => `#icon-${props.name}`)
const svgClass = computed(() =>
  props.className ? `#icon-${props.className}` : 'svg-icon'
)
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  outline: none;
  overflow: hidden;
}
</style>
